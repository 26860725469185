import * as React from 'react'
import { footer } from '../styles/footer.module.css'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { FaGithub } from '@react-icons/all-files/fa/FaGithub'
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin'
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'

const Footer = () => {
    return (
        <footer id={footer} className="p-2 text-center">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        &copy; 2021 Andrew M. Kane | Icons by Font Awesome &amp; Game Icons
                    </div>
                    <div className="col">
                        <OutboundLink href="https://github.com/codemaster"><FaGithub aria-label='GitHub' /></OutboundLink>
                        <OutboundLink href="https://www.linkedin.com/in/codemaster"><FaLinkedin aria-label='LinkedIn' /></OutboundLink>
                        <OutboundLink href="https://www.facebook.com/codemastermm"><FaFacebook aria-label='Facebook' /></OutboundLink>
                        <OutboundLink href="https://twitter.com/codemastermm"><FaTwitter aria-label='Twitter' /></OutboundLink>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
