import * as React from 'react'
import { Link } from 'gatsby'
import { navbar } from '../styles/navbar.module.css'
import { GiConsoleController } from '@react-icons/all-files/gi/GiConsoleController'
import { AiOutlineApi } from '@react-icons/all-files/ai/AiOutlineApi'
import { AiOutlineDatabase } from '@react-icons/all-files/ai/AiOutlineDatabase'

const NavBar = () => {
    return (
        <nav id={navbar} className="align-self-stretch navbar navbar-expand-md navbar-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <GiConsoleController aria-label='Game' />
                    <AiOutlineApi aria-label='API' />
                    <AiOutlineDatabase aria-label='Database' />
                </a>
                <button className="navbar-toggler" type="button" data-bs-target="#navbarContent" aria-controls="navbarContent" data-bs-toggle="collapse" aria-expanded="false" aria-label="Toggle Navigation">
                    <span className="navbar-toggler-icon" />
                </button>

                <div className="collapse navbar-collapse" id="navbarContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Link to="/" activeClassName="active" className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/projects" activeClassName="active" className="nav-link">Projects</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/work" activeClassName="active" className="nav-link">Work</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/education" activeClassName="active" className="nav-link">Education</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/activities" activeClassName="active" className="nav-link">Activities</Link>
                        </li>
                        <li className="nav-item">
                            <a href="/resume/Andrew_Kane_Resume.pdf" className="nav-link">Resume</a>
                        </li>
                        <li className="nav-item">
                            <a href="mailto:andrew@andrewmkane.com" className="nav-link">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;
