import * as React from 'react'
import NavBar from './navbar'
import Footer from './footer'
import { content, main } from '../styles/layout.module.css'

const Layout = ({ valign, align, children }) => {
    return (
        <main id={main} className="h-100 d-flex flex-column">
            <NavBar />
            <section id={content} className={"align-self-stretch flex-fill d-flex flex-column justify-content-" + (valign || "start") + " text-" + (align || "left")}>
                {children}
            </section>
            <Footer />
        </main>
    )
}

export default Layout
